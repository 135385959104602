import MarketplaceService from 'src/modules/marketplace/marketplaceService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'MARKETPLACE_ORDER_BOOK';

const marketplaceOrderBookActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  CALCULATE_STARTED: `${prefix}_CALCULATE_STARTED`,
  CALCULATE_SUCCESS: `${prefix}_CALCULATE_SUCCESS`,
  CALCULATE_ERROR: `${prefix}_CALCULATE_ERROR`,

  doFind: (propertyId) => async (dispatch) => {
    try {
      dispatch({
        type: marketplaceOrderBookActions.FIND_STARTED,
      });

      const record = await MarketplaceService.findOrderBook(propertyId);

      dispatch({
        type: marketplaceOrderBookActions.FIND_SUCCESS,
        payload: {
          buyingOrdersList: record.buyingOrdersList,
          sellingOrdersList: record.sellingOrdersList,
          filledOrdersList: record.filledOrdersList,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: marketplaceOrderBookActions.FIND_ERROR,
      });
    }
  },

  doCalculate: (id) => async (dispatch) => {
    try {
      dispatch({
        type: marketplaceOrderBookActions.CALCULATE_STARTED,
      });

      const payload = await MarketplaceService.calculate(id);

      dispatch({
        type: marketplaceOrderBookActions.CALCULATE_SUCCESS,
        payload: {
          lastTradeTokenPrice: payload.lastTradeTokenPrice,
          amountForMonth: payload.amountForMonth,
          tokenAmountToSell: payload.tokenAmountToSell,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: marketplaceOrderBookActions.CALCULATE_ERROR,
      });
    }
  },
};

export default marketplaceOrderBookActions;
