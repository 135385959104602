import actions from 'src/modules/exchangeRate/exchangeRateActions';

const initialState = {
  rows: [] as Array<any>,
  currency: 'USD',
  record: null,
  count: 0,
  loading: false,
  findLoading: false,
};

export default (
  state = initialState,
  { type, payload },
) => {
  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    };
  }
  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      record: null,
      findLoading: true,
    };
  }

  if (type === actions.FIND_SUCCESS) {
    return {
      ...state,
      record: payload,
      findLoading: false,
    };
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      record: null,
      findLoading: false,
    };
  }

  if (type === actions.SET_CURRENCY) {
    return {
      ...state,
      currency: payload,
    };
  }

  return state;
};
