import authAxios from 'src/modules/shared/axios/authAxios';

export default class ExchangeRateService {
  static async find(currency) {
    const response = await authAxios.get(`/exchange-rate/${currency}`);

    return response.data;
  }

  static async list() {

    const response = await authAxios.get(`/exchange-rate`, {});

    return response.data;
  }
}
