import PropertyService from 'src/modules/property/propertyService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'PRICE_LIST';

const priceListActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (propertyId, startDate = null) => async (dispatch) => {
    try {
      dispatch({
        type: priceListActions.FIND_STARTED,
      });

      const rows = await PropertyService.priceList(propertyId, startDate);

      dispatch({
        type: priceListActions.FIND_SUCCESS,
        payload: rows,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: priceListActions.FIND_ERROR,
      });
    }
  },
};

export default priceListActions;
