import authAxios from 'src/modules/shared/axios/authAxios';

export default class PaymentService {
  static async find(id) {
    const response = await authAxios.get(`/payment/${id}`);

    return response.data;
  }

  static async findFIAT(investor) {
    const response = await authAxios.get(`/fiat/${investor}`);

    return response.data;
  }

  static async requestPurchase(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/payment/request-purchase`,
      body,
    );

    return response.data;
  }

  static async purchase(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/payment/purchase/${id}`,
      body,
    );

    return response.data;
  }

  static async acceptReinvestment(id) {
    const body = {
      id
    };

    const response = await authAxios.put(
      `/payment/accept-reinvestment/${id}`,
      body,
    );

    return response.data;
  }

  static async declineReinvestment(id) {
    const body = {
      id
    };

    const response = await authAxios.put(
      `/payment/decline-reinvestment/${id}`,
      body,
    );

    return response.data;
  }

  static async acceptWithdraw(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/payment/accept-withdraw/${id}`,
      body,
    );

    return response.data;
  }

  static async sendDividends(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/payment/send-dividends`,
      body,
    );

    return response.data;
  }

  static async sendDividend(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/payment/send-dividend/${data.id}`,
      body,
    );

    return response.data;
  }

  static async withdraw() {
    const body = {};

    const response = await authAxios.post(
      `/payment/withdraw`,
      body,
    );

    return response.data;
  }

  static async claimDividend() {
    const body = {};

    const response = await authAxios.post(
      `/payment/claim-dividend`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/payment`, {
      params,
    });

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/payment`, {
      params,
    });

    return response.data;
  }

  static async khipuCreate(data) {
    const body = {
      data
    };
    const response = await authAxios.post(`/khipu/create`, body);

    return response.data;
  }

  static async khipuConfirmStatus(transactionId) {
    const body = {
      transactionId
    }

    const response = await authAxios.post(`/khipu/confirm-status`, body);

    return response.data;
  }

  static async khipuCancel(transactionId) {

    const response = await authAxios.put(`/khipu/cancel/${transactionId}`);

    return response.data;
  }

  static async getPurchaseHistory(propertyId) {
    const response = await authAxios.get(`/payment/purchase-history/${propertyId}`);

    return response.data;
  }
}
