import {
  NETWORK_NAME,
  RPC_URL,
  BLOCK_EXPLORER_URL,
  CURRENCY_SYMBOL,
  TOKEN_DECIMALS,
} from 'src/web3/constants';

export default async function switchNetwork() {
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x38' }],
      });
    } catch (error: any) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x38',
                chainName: NETWORK_NAME,
                rpcUrls: [RPC_URL],
                blockExplorerUrls: [BLOCK_EXPLORER_URL],
                nativeCurrency: {
                  name: CURRENCY_SYMBOL,
                  symbol: CURRENCY_SYMBOL,
                  decimals: TOKEN_DECIMALS,
                },
              },
            ],
          });
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x38' }],
          });
        } catch (addError) {
          console.log(error);
        }
      }
      console.log(error);
    }
  }
}
