import MarketplaceService from 'src/modules/marketplace/marketplaceService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';
import marketplaceOrderBookActions from 'src/modules/marketplace/orderBook/marketplaceOrderBookActions';

const prefix = 'MARKETPLACE_FORM';

const marketplaceFormActions = {
  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  FILL_STARTED: `${prefix}_FILL_STARTED`,
  FILL_SUCCESS: `${prefix}_FILL_SUCCESS`,
  FILL_ERROR: `${prefix}_FILL_ERROR`,

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: marketplaceFormActions.CREATE_STARTED,
      });

      await MarketplaceService.create(values);

      Message.success(i18n('marketplace.doAddSuccess'));

      dispatch(marketplaceOrderBookActions.doFind(values.property));

      dispatch({
        type: marketplaceFormActions.CREATE_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: marketplaceFormActions.CREATE_ERROR,
      });
    }
  },

  doFill: (id, propertyId, values) => async (dispatch) => {
    try {
      dispatch({
        type: marketplaceFormActions.FILL_STARTED,
      });

      await MarketplaceService.fill(id, values);

      Message.success(i18n('marketplace.doFillSuccess'));

      dispatch(marketplaceOrderBookActions.doFind(propertyId));

      dispatch({
        type: marketplaceFormActions.FILL_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: marketplaceFormActions.FILL_ERROR,
      });
    }
  },
};

export default marketplaceFormActions;
