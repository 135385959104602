/* eslint-disable prefer-destructuring */
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Button from '@mui/material/Button';
import { styled, Theme } from '@mui/material/styles';

export default styled(Button)(
  ({
    theme,
    ownerState,
  }: {
    theme?: Theme | any;
    ownerState: any;
  }) => {
    const { palette, functions, borders, boxShadows } =
      theme;
    const {
      color,
      variant,
      size,
      circular,
      iconOnly,
      darkMode,
    } = ownerState;

    const { white, text, transparent, gradients, grey } =
      palette;
    const { boxShadow, linearGradient, pxToRem, rgba } =
      functions;
    const { borderRadius } = borders;
    const { colored } = boxShadows;

    // styles for the button with variant="contained"
    const containedStyles = () => {
      // background color value
      const backgroundValue = palette[color]
        ? palette[color].main
        : white.main;

      // backgroundColor value when button is focused
      const focusedBackgroundValue = palette[color]
        ? palette[color].focus
        : white.focus;

      // boxShadow value
      const boxShadowValue = colored[color]
        ? `${boxShadow(
            [0, 3],
            [3, 0],
            palette[color].main,
            0.15,
          )}, ${boxShadow(
            [0, 3],
            [1, -2],
            palette[color].main,
            0.2,
          )}, ${boxShadow(
            [0, 1],
            [5, 0],
            palette[color].main,
            0.15,
          )}`
        : 'none';

      // boxShadow value when button is hovered
      const hoveredBoxShadowValue = colored[color]
        ? `${boxShadow(
            [0, 14],
            [26, -12],
            palette[color].main,
            0.4,
          )}, ${boxShadow(
            [0, 4],
            [23, 0],
            palette[color].main,
            0.15,
          )}, ${boxShadow(
            [0, 8],
            [10, -5],
            palette[color].main,
            0.2,
          )}`
        : 'none';

      // color value
      let colorValue = white.main;

      if (
        !darkMode &&
        (color === 'white' ||
          color === 'light' ||
          !palette[color])
      ) {
        colorValue = text.main;
      } else if (
        darkMode &&
        (color === 'white' ||
          color === 'light' ||
          !palette[color])
      ) {
        colorValue = grey[600];
      }

      // color value when button is focused
      let focusedColorValue = white.main;

      if (color === 'white') {
        focusedColorValue = text.main;
      } else if (
        color === 'primary' ||
        color === 'error' ||
        color === 'dark'
      ) {
        focusedColorValue = white.main;
      }

      return {
        background: backgroundValue,
        color: colorValue,
        boxShadow: boxShadowValue,

        '&:hover': {
          backgroundColor: backgroundValue,
          color: colorValue,
          boxShadow: hoveredBoxShadowValue,
        },

        '&:focus:not(:hover)': {
          backgroundColor: focusedBackgroundValue,
          color: colorValue,
          boxShadow: palette[color]
            ? boxShadow(
                [0, 0],
                [0, 3.2],
                palette[color].main,
                0.5,
              )
            : boxShadow([0, 0], [0, 3.2], white.main, 0.5),
        },

        '&:disabled': {
          backgroundColor: backgroundValue,
          color: focusedColorValue,
        },
      };
    };

    // styles for the button with variant="outlined"
    const outlinedStyles = () => {
      // background color value
      const backgroundValue =
        color === 'white'
          ? rgba(white.main, 0.1)
          : transparent.main;

      // color value
      const colorValue = palette[color]
        ? palette[color].main
        : white.main;

      // boxShadow value
      const boxShadowValue = palette[color]
        ? boxShadow(
            [0, 0],
            [0, 3.2],
            palette[color].main,
            0.5,
          )
        : boxShadow([0, 0], [0, 3.2], white.main, 0.5);

      // border color value
      let borderColorValue = palette[color]
        ? palette[color].main
        : rgba(white.main, 0.75);

      if (color === 'white') {
        borderColorValue = rgba(white.main, 0.75);
      }

      return {
        background: backgroundValue,
        color: colorValue,
        border: `${pxToRem(0.8)} solid ${borderColorValue}`,

        '&:hover': {
          background: transparent.main,
          color: colorValue,
          borderColor: colorValue,
          opacity: 0.85,
        },

        '&:focus:not(:hover)': {
          background: transparent.main,
          color: colorValue,
          boxShadow: boxShadowValue,
        },

        '&:active:not(:hover)': {
          backgroundColor: colorValue,
          color: white.main,
          opacity: 0.85,
        },

        '&:disabled': {
          color: colorValue,
          borderColor: colorValue,
        },
      };
    };

    // styles for the button with variant="gradient"
    const gradientStyles = () => {
      // background value
      const backgroundValue =
        color === 'white' || !gradients[color]
          ? white.main
          : linearGradient(
              gradients[color].main,
              gradients[color].state,
            );

      // boxShadow value
      const boxShadowValue = colored[color]
        ? `${boxShadow(
            [0, 3],
            [3, 0],
            palette[color].main,
            0.15,
          )}, ${boxShadow(
            [0, 3],
            [1, -2],
            palette[color].main,
            0.2,
          )}, ${boxShadow(
            [0, 1],
            [5, 0],
            palette[color].main,
            0.15,
          )}`
        : 'none';

      // boxShadow value when button is hovered
      const hoveredBoxShadowValue = colored[color]
        ? `${boxShadow(
            [0, 14],
            [26, -12],
            palette[color].main,
            0.4,
          )}, ${boxShadow(
            [0, 4],
            [23, 0],
            palette[color].main,
            0.15,
          )}, ${boxShadow(
            [0, 8],
            [10, -5],
            palette[color].main,
            0.2,
          )}`
        : 'none';

      // color value
      let colorValue = white.main;

      if (color === 'white') {
        colorValue = text.main;
      } else if (color === 'light') {
        colorValue = gradients.dark.state;
      }

      return {
        background: backgroundValue,
        color: colorValue,
        boxShadow: boxShadowValue,

        '&:hover': {
          boxShadow: hoveredBoxShadowValue,
          color: colorValue,
        },

        '&:focus:not(:hover)': {
          boxShadow: boxShadowValue,
          color: colorValue,
        },

        '&:disabled': {
          background: backgroundValue,
          color: colorValue,
        },
      };
    };

    // styles for the button with variant="text"
    const textStyles = () => {
      // color value
      const colorValue = palette[color]
        ? palette[color].main
        : white.main;

      // color value when button is focused
      const focusedColorValue = palette[color]
        ? palette[color].focus
        : white.focus;

      return {
        color: colorValue,

        '&:hover': {
          color: focusedColorValue,
        },

        '&:focus:not(:hover)': {
          color: focusedColorValue,
        },
      };
    };

    // styles for the button with circular={true}
    const circularStyles = () => ({
      borderRadius: borderRadius.section,
    });

    // styles for the button with iconOnly={true}
    const iconOnlyStyles = () => {
      // width, height, minWidth and minHeight values
      let sizeValue = pxToRem(30.4);

      if (size === 'small') {
        sizeValue = pxToRem(20.32);
      } else if (size === 'large') {
        sizeValue = pxToRem(41.6);
      }

      // padding value
      let paddingValue = `${pxToRem(8.8)} ${pxToRem(
        11,
      )} ${pxToRem(8)}`;

      if (size === 'small') {
        paddingValue = pxToRem(3.6);
      } else if (size === 'large') {
        paddingValue = pxToRem(12.8);
      }

      return {
        width: sizeValue,
        minWidth: sizeValue,
        height: sizeValue,
        minHeight: sizeValue,
        padding: paddingValue,

        '& .material-icons': {
          marginTop: 0,
        },

        '&:hover, &:focus, &:active': {
          transform: 'none',
        },
      };
    };

    return {
      ...(variant === 'contained' && containedStyles()),
      ...(variant === 'outlined' && outlinedStyles()),
      ...(variant === 'gradient' && gradientStyles()),
      ...(variant === 'text' && textStyles()),
      ...(circular && circularStyles()),
      ...(iconOnly && iconOnlyStyles()),
    };
  },
);
