import authAxios from 'src/modules/shared/axios/authAxios';

export default class MarketplaceService {
  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/marketplace`,
      body,
    );

    return response.data;
  }

  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/marketplace/${id}`,
      body,
    );

    return response.data;
  }

  static async cancel(id) {

    const response = await authAxios.put(`/marketplace/cancel/${id}`);

    return response.data;
  }

  static async fill(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/marketplace/fill/${id}`,
      body,
    );

    return response.data;
  }

  static async confirmTransaction(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/marketplace/confirm-transaction/${id}`,
      body,
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/marketplace`, {
      params,
    });

    return response.data;
  }

  static async find() {
    const response = await authAxios.get(`/marketplace/find`);

    return response.data;
  }

  static async findOrderBook(propertyId) {
    const response = await authAxios.get(`/marketplace/order-book/${propertyId}`);

    return response.data;
  }

  static async calculate(propertyId) {
    const response = await authAxios.get(`/marketplace/calculate/${propertyId}`);

    return response.data;
  }

  static async getPropertySaleInfo(propertyId) {
    const response = await authAxios.get(`/marketplace/sale-info/${propertyId}`);

    return response.data;
  }
}
