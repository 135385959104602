import { createSelector } from 'reselect';

const selectRaw = (state) => state.exchangeRate;

const selectLoading = createSelector(
  [selectRaw],
  (raw) => Boolean(raw.loading),
);

const selectFindLoading = createSelector(
  [selectRaw],
  (raw) => Boolean(raw.findLoading),
);

const selectRows = createSelector(
  [selectRaw],
  (raw) => raw.rows,
);

const selectRecord = createSelector(
  [selectRaw],
  (raw) => raw.record,
);

const selectCount = createSelector(
  [selectRaw],
  (raw) => raw.count,
);

const selectHasRows = createSelector(
  [selectCount],
  (count) => count > 0,
);

const selectCurrency = createSelector(
  [selectRaw],
  (raw) => raw.currency,
);

const exchangeRateSelectors = {
  selectRaw,
  selectLoading,
  selectFindLoading,
  selectRows,
  selectRecord,
  selectCount,
  selectHasRows,
  selectCurrency,
};

export default exchangeRateSelectors;
