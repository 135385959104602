import MarketplaceService from 'src/modules/marketplace/marketplaceService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';

const prefix = 'MARKETPLACE_VIEW';

const marketplaceViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  CANCEL_STARTED: `${prefix}_CANCEL_STARTED`,
  CANCEL_SUCCESS: `${prefix}_CANCEL_SUCCESS`,
  CANCEL_ERROR: `${prefix}_CANCEL_ERROR`,

  doFind: () => async (dispatch) => {
    try {
      dispatch({
        type: marketplaceViewActions.FIND_STARTED,
      });

      const record = await MarketplaceService.find();

      dispatch({
        type: marketplaceViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: marketplaceViewActions.FIND_ERROR,
      });
    }
  },

  doUpdate: (id, data) => async (dispatch) => {
    try {
      dispatch({
        type: marketplaceViewActions.UPDATE_STARTED,
      });

      await MarketplaceService.update(id, data);

      Message.success(i18n('marketplace.doUpdateSuccess'));

      dispatch(marketplaceViewActions.doFind());

      dispatch({
        type: marketplaceViewActions.UPDATE_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: marketplaceViewActions.UPDATE_ERROR,
      });
    }
  },

  doCancel: (id) => async (dispatch) => {
    try {
      dispatch({
        type: marketplaceViewActions.CANCEL_STARTED,
      });

      await MarketplaceService.cancel(id);

      Message.success(i18n('marketplace.doCancelSuccess'));

      dispatch(marketplaceViewActions.doFind());

      dispatch({
        type: marketplaceViewActions.CANCEL_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: marketplaceViewActions.CANCEL_ERROR,
      });
    }
  }
};

export default marketplaceViewActions;
