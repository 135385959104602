import actions from 'src/modules/marketplace/orderBook/marketplaceOrderBookActions';

const initialData = {
  buyingOrdersList: [],
  sellingOrdersList: [],
  filledOrdersList: [],
  lastTradeTokenPrice: 0,
  amountForMonth: 0,
  tokenAmountToSell: 0,
  loading: false,
  calculateLoading: false,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      buyingOrdersList: [],
      sellingOrdersList: [],
      filledOrdersList: [],
      loading: true,
    };
  }

  if (type === actions.FIND_SUCCESS) {
    return {
      ...state,
      buyingOrdersList: payload.buyingOrdersList,
      sellingOrdersList: payload.sellingOrdersList,
      filledOrdersList: payload.filledOrdersList,
      loading: false,
    };
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      buyingOrdersList: [],
      sellingOrdersList: [],
      filledOrdersList: [],
      loading: false,
    };
  }

  if (type === actions.CALCULATE_STARTED) {
    return {
      ...state,
      lastTradeTokenPrice: 0,
      amountForMonth: 0,
      tokenAmountToSell: 0,
      calculateLoading: true,
    };
  }

  if (type === actions.CALCULATE_SUCCESS) {
    return {
      ...state,
      lastTradeTokenPrice: payload.lastTradeTokenPrice,
      amountForMonth: payload.amountForMonth,
      tokenAmountToSell: payload.tokenAmountToSell,
      calculateLoading: false,
    };
  }

  if (type === actions.CALCULATE_ERROR) {
    return {
      ...state,
      lastTradeTokenPrice: 0,
      amountForMonth: 0,
      tokenAmountToSell: 0,
      calculateLoading: false,
    };
  }

  return state;
};
