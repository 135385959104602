import actions from 'src/modules/web3/web3Actions';

const initialData = {
  approveLoading: false,
  buyTokenLoading: false,
  sendDividendLoading: false,
  sendDividendsLoading: false,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.APPROVE_START) {
    return {
      ...state,
      approveLoading: true,
    };
  }

  if (type === actions.APPROVE_END) {
    return {
      ...state,
      approveLoading: false,
    };
  }

  if (type === actions.BUY_TOKEN_START) {
    return {
      ...state,
      buyTokenLoading: true,
    };
  }

  if (type === actions.BUY_TOKEN_END) {
    return {
      ...state,
      buyTokenLoading: false,
    };
  }

  if (type === actions.SEND_DIVIDEND_START) {
    return {
      ...state,
      sendDividendLoading: true,
    };
  }

  if (type === actions.SEND_DIVIDEND_END) {
    return {
      ...state,
      sendDividendLoading: false,
    };
  }

  if (type === actions.SEND_DIVIDENDS_START) {
    return {
      ...state,
      sendDividendsLoading: true,
    };
  }

  if (type === actions.SEND_DIVIDENDS_END) {
    return {
      ...state,
      sendDividendsLoading: false,
    };
  }

  return state;
};
