import actions from 'src/modules/dashboard/dashboardActions';

const initialState = {
  countTotalCustomer: 0,
  countVerifiedCustomer: 0,
  countRequestedCustomer: 0,
  countCountry: 0,
  countPurchasePendingTransactions: 0,
  totalForSendDividend: 0,
  totalForPendingWithdrawn: 0,
  totalForProceedWithdrawn: 0,
  loadingCountTotalCustomer: false,
  loadingCountVerifiedCustomer: false,
  loadingCountRequestedCustomer: false,
  loadingCountCountry: false,
  loadingCountPurchasePendingTransactions: false,
  loadingTotalForSendDividend: false,
  loadingTotalForPendingWithdrawn: false,
  loadingTotalForProceedWithdrawn: false,
  valuations: [],
  interests: [],
  loadingValuations: false,
  loadingInterests: false,
};

export default (
  state = initialState,
  { type, payload },
) => {
  if (type === actions.COUNT_TOTAL_CUSTOMER_STARTED) {
    return {
      ...state,
      loadingCountTotalCustomer: true,
    };
  }

  if (type === actions.COUNT_TOTAL_CUSTOMER_SUCCESS) {
    return {
      ...state,
      loadingCountTotalCustomer: false,
      countTotalCustomer: payload.count,
    };
  }

  if (type === actions.COUNT_TOTAL_CUSTOMER_ERROR) {
    return {
      ...state,
      loadingCountTotalCustomer: false,
    };
  }

  if (type === actions.COUNT_VERIFIED_CUSTOMER_STARTED) {
    return {
      ...state,
      loadingCountVerifiedCustomer: true,
    };
  }

  if (type === actions.COUNT_VERIFIED_CUSTOMER_SUCCESS) {
    return {
      ...state,
      loadingCountVerifiedCustomer: false,
      countVerifiedCustomer: payload.count,
    };
  }

  if (type === actions.COUNT_VERIFIED_CUSTOMER_ERROR) {
    return {
      ...state,
      loadingCountVerifiedCustomer: false,
    };
  }

  if (type === actions.COUNT_REQUESTED_CUSTOMER_STARTED) {
    return {
      ...state,
      loadingCountRequestedCustomer: true,
    };
  }

  if (type === actions.COUNT_REQUESTED_CUSTOMER_SUCCESS) {
    return {
      ...state,
      loadingCountRequestedCustomer: false,
      countRequestedCustomer: payload.count,
    };
  }

  if (type === actions.COUNT_REQUESTED_CUSTOMER_ERROR) {
    return {
      ...state,
      loadingCountRequestedCustomer: false,
    };
  }

  if (type === actions.COUNT_COUNTRY_STARTED) {
    return {
      ...state,
      loadingCountCountry: true,
    };
  }

  if (type === actions.COUNT_COUNTRY_SUCCESS) {
    return {
      ...state,
      loadingCountCountry: false,
      countCountry: payload.count,
    };
  }

  if (type === actions.COUNT_COUNTRY_ERROR) {
    return {
      ...state,
      loadingCountCountry: false,
    };
  }

  if (type === actions.COUNT_PURCHASE_PENDING_TRANSACTIONS_STARTED) {
    return {
      ...state,
      loadingCountPurchasePendingTransactions: true,
    };
  }

  if (type === actions.COUNT_PURCHASE_PENDING_TRANSACTIONS_SUCCESS) {
    return {
      ...state,
      loadingCountPurchasePendingTransactions: false,
      countPurchasePendingTransactions: payload.count,
    };
  }

  if (type === actions.COUNT_PURCHASE_PENDING_TRANSACTIONS_ERROR) {
    return {
      ...state,
      loadingCountPurchasePendingTransactions: false,
    };
  }

  if (type === actions.TOTAL_FOR_SEND_DIVIDEND_STARTED) {
    return {
      ...state,
      loadingTotalForSendDividend: true,
    };
  }

  if (type === actions.TOTAL_FOR_SEND_DIVIDEND_SUCCESS) {
    return {
      ...state,
      loadingTotalForSendDividend: false,
      totalForSendDividend: payload.total,
    };
  }

  if (type === actions.TOTAL_FOR_SEND_DIVIDEND_ERROR) {
    return {
      ...state,
      loadingTotalForSendDividend: false,
    };
  }

  if (type === actions.TOTAL_FOR_PENDING_WITHDRAWN_STARTED) {
    return {
      ...state,
      loadingTotalForPendingWithdrawn: true,
    };
  }

  if (type === actions.TOTAL_FOR_PENDING_WITHDRAWN_SUCCESS) {
    return {
      ...state,
      loadingTotalForPendingWithdrawn: false,
      totalForPendingWithdrawn: payload.total,
    };
  }

  if (type === actions.TOTAL_FOR_PENDING_WITHDRAWN_ERROR) {
    return {
      ...state,
      loadingTotalForPendingWithdrawn: false,
    };
  }

  if (type === actions.TOTAL_FOR_PROCEED_WITHDRAWN_STARTED) {
    return {
      ...state,
      loadingTotalForProceedWithdrawn: true,
    };
  }

  if (type === actions.TOTAL_FOR_PROCEED_WITHDRAWN_SUCCESS) {
    return {
      ...state,
      loadingTotalForProceedWithdrawn: false,
      totalForProceedWithdrawn: payload.total,
    };
  }

  if (type === actions.TOTAL_FOR_PROCEED_WITHDRAWN_ERROR) {
    return {
      ...state,
      loadingTotalForProceedWithdrawn: false,
    };
  }

  if (type === actions.GET_VALUATIONS_STARTED) {
    return {
      ...state,
      loadingValuations: true,
    };
  }

  if (type === actions.GET_VALUATIONS_SUCCESS) {
    return {
      ...state,
      loadingValuations: false,
      valuations: payload
    };
  }

  if (type === actions.GET_VALUATIONS_ERROR) {
    return {
      ...state,
      loadingValuations: false,
      valuations: [],
    };
  }

  if (type === actions.GET_INTERESTS_STARTED) {
    return {
      ...state,
      loadingInterests: true,
    };
  }

  if (type === actions.GET_INTERESTS_SUCCESS) {
    return {
      ...state,
      loadingInterests: false,
      interests: payload,
    };
  }

  if (type === actions.GET_INTERESTS_ERROR) {
    return {
      ...state,
      loadingInterests: false,
      interests: []
    };
  }

  return state;
};
