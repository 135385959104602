const backendUrl = `/api`;

const frontendUrl = {
  host: 'www.bloktok.io',
  protocol: 'https',
};

export default {
  frontendUrl,
  backendUrl,
};