import { ConnectedRouter } from 'connected-react-router';
import {
  configureStore,
  getHistory,
} from 'src/modules/store';
import { useState, useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import RoutesComponent from 'src/view/shared/routes/RoutesComponent';
import 'typeface-roboto';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Message from 'src/view/shared/message';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Material Dashboard 2 PRO React TS themes
import theme from 'src/mui/assets/theme';
import themeRTL from 'src/mui/assets/theme/theme-rtl';

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from 'src/mui/assets/theme-dark';
import themeDarkRTL from 'src/mui/assets/theme-dark/theme-rtl';

// RTL plugins
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// for MUI 2 Dashboard
import { selectMuiSettings } from 'src/modules/mui/muiSelectors';
import Menu from 'src/view/layout/Menu';
import { Web3ContextProvider } from 'src/web3/hooks/web3Context';
import { HelmetProvider } from 'react-helmet-async';

const store = configureStore();

export default function App(props) {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <Web3ContextProvider>
          <AppWithRedux {...props} />
        </Web3ContextProvider>
      </HelmetProvider>
    </Provider>
  );
}

function AppWithRedux(props) {
  const { direction, darkMode } = selectMuiSettings();

  const [rtlCache, setRtlCache] = useState(null);

  // Cache for the rtl
  useMemo(() => {
    const pluginRtl: any = rtlPlugin;
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [pluginRtl],
    });

    setRtlCache(cacheRtl);
  }, []);

  return direction === 'rtl' ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider
        theme={darkMode ? themeDarkRTL : themeRTL}
      >
        <SnackbarProvider maxSnack={3}>
          <>
            <CssBaseline />
            <AppWithSnackbar {...props} />
          </>
        </SnackbarProvider>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <SnackbarProvider maxSnack={3}>
        <>
          <CssBaseline />
          <AppWithSnackbar {...props} />
        </>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

function AppWithSnackbar(props) {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // this is a little hack to not have to import notistack
    // on all the components that emit messages
    Message.registerNotistakEnqueueSnackbar(
      enqueueSnackbar,
    );
  }, [enqueueSnackbar]);

  const {
    sidenavColor,
    direction,
    layout,
  } = selectMuiSettings();

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.className = `${sidenavColor}-scrollbar`;
  }, [sidenavColor]);

  return (
    <ConnectedRouter history={getHistory()}>
      {layout === 'dashboard' && (
        <Menu />
      )}
      {layout === 'customer' && <Menu />}
      <RoutesComponent />
    </ConnectedRouter>
  );
}
