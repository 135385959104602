import { selectMuiSettings } from 'src/modules/mui/muiSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import MDBox from 'src/mui/components/MDBox';
import muiActions from 'src/modules/mui/muiActions';
import Scrollbar from 'react-smooth-scrollbar-z';
import SEO from 'src/view/shared/SEO';
import ChatwootWidget from 'src/view/home/globalComponents/ChatWidget';

function DashboardLayout({
  children,
  isCustomer,
}: {
  children: ReactNode;
  isCustomer: Boolean;
}): JSX.Element {
  const [isChat, setChat] = useState(false);
  const dispatch = useDispatch();
  const { miniSidenav } = selectMuiSettings();
  const { pathname } = useLocation();
  const adminTitle = 'Admin';
  const adminDescription = 'Admin';
  const customerTitle = 'Cuenta personal';
  const customerDescription = 'Mi cuenta en Bloktok.io';


  useEffect(() => {
    if (isCustomer) {
      setChat(true);
      dispatch(muiActions.doLayout('customer'));
    } else {
      setChat(false);
      dispatch(muiActions.doLayout('dashboard'));
    }
  }, [pathname, isCustomer]);

  return (
    <>
      <SEO
        title={isCustomer ? customerTitle : adminTitle}
        description={isCustomer ? customerDescription : adminDescription}
      />
      {isChat ? <ChatwootWidget /> : null}
      <MDBox
        sx={({
          breakpoints,
          transitions,
          functions: { pxToRem },
        }) => ({
          p: 1.2,
          position: 'relative',

          [breakpoints.up('xl')]: {
            marginLeft: miniSidenav
              ? pxToRem(90)
              : pxToRem(263),
            transition: transitions.create(
              ['margin-left', 'margin-right'],
              {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
              },
            ),
          },
        })}
      >
        {children}
      </MDBox>
    </>
  );
}

export default DashboardLayout;
