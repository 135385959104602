import {
  JsonRpcProvider,
  StaticJsonRpcProvider,
} from '@ethersproject/providers';
import { ethers } from 'ethers';
import { Governance_ABI } from 'src/web3/constants/abi/Governance';
import convertToDecimal from 'src/web3/helpers/convertToDecimal';

export default async function getClaimableAmount(
  governanceAddress: string,
  provider: JsonRpcProvider | StaticJsonRpcProvider,
) {
  const signer = provider.getSigner();

  const governanceContract = new ethers.Contract(
    governanceAddress,
    Governance_ABI,
    signer,
  );

  const amount =
    await governanceContract.getClaimableStable();

  return convertToDecimal(amount);
}
