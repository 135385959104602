const DEFAULT_MOMENT_FORMAT = 'DD-MM-YYYY hh:mm A';
const DEFAULT_MOMENT_FORMAT_DATE_ONLY = 'DD-MM-YYYY';
const DEFAULT_MOMENT_FORMAT_TIME_ONLY = 'hh:mm A';
const DEFAULT_PICKER_FORMAT = 'dd-MM-yyyy hh:mm a';
const DEFAULT_PICKER_FORMAT_DATE_ONLY = 'dd-MM-yyyy';
const DEFAULT_PICKER_FORMAT_TIME_ONLY = 'hh:mm a';
const GOOGLE_MAPS_API_KEY =
  'AIzaSyDQS9BWtn4TMAEXDPbz2DXDNVuK9jXiECw';
const KHIPU_FEE = 750;

export {
  DEFAULT_MOMENT_FORMAT,
  DEFAULT_MOMENT_FORMAT_DATE_ONLY,
  DEFAULT_MOMENT_FORMAT_TIME_ONLY,
  DEFAULT_PICKER_FORMAT,
  DEFAULT_PICKER_FORMAT_DATE_ONLY,
  DEFAULT_PICKER_FORMAT_TIME_ONLY,
  GOOGLE_MAPS_API_KEY,
  KHIPU_FEE
};
