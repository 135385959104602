import { getHistory } from 'src/modules/store';
import authSelectors from 'src/modules/auth/authSelectors';
import Errors from 'src/modules/shared/error/errors';
import dashboardSelectors from 'src/modules/dashboard/dashboardSelectors';
import DashboardService from 'src/modules/dashboard/dashboardService';

const prefix = 'DASHBOARD_ACTIONS';

const dashboardActions = {
  COUNT_TOTAL_CUSTOMER_STARTED: `${prefix}_COUNT_TOTAL_CUSTOMER_STARTED`,
  COUNT_TOTAL_CUSTOMER_SUCCESS: `${prefix}_COUNT_TOTAL_CUSTOMER_SUCCESS`,
  COUNT_TOTAL_CUSTOMER_ERROR: `${prefix}_COUNT_TOTAL_CUSTOMER_ERROR`,

  COUNT_VERIFIED_CUSTOMER_STARTED: `${prefix}_COUNT_VERIFIED_CUSTOMER_STARTED`,
  COUNT_VERIFIED_CUSTOMER_SUCCESS: `${prefix}_COUNT_VERIFIED_CUSTOMER_SUCCESS`,
  COUNT_VERIFIED_CUSTOMER_ERROR: `${prefix}_COUNT_VERIFIED_CUSTOMER_ERROR`,

  COUNT_REQUESTED_CUSTOMER_STARTED: `${prefix}_COUNT_REQUESTED_CUSTOMER_STARTED`,
  COUNT_REQUESTED_CUSTOMER_SUCCESS: `${prefix}_COUNT_REQUESTED_CUSTOMER_SUCCESS`,
  COUNT_REQUESTED_CUSTOMER_ERROR: `${prefix}_COUNT_REQUESTED_CUSTOMER_ERROR`,

  COUNT_COUNTRY_STARTED: `${prefix}_COUNT_COUNTRY_STARTED`,
  COUNT_COUNTRY_SUCCESS: `${prefix}_COUNT_COUNTRY_SUCCESS`,
  COUNT_COUNTRY_ERROR: `${prefix}_COUNT_COUNTRY_ERROR`,

  COUNT_PURCHASE_PENDING_TRANSACTIONS_STARTED: `${prefix}_COUNT_PURCHASE_PENDING_TRANSACTIONS_STARTED`,
  COUNT_PURCHASE_PENDING_TRANSACTIONS_SUCCESS: `${prefix}_COUNT_PURCHASE_PENDING_TRANSACTIONS_SUCCESS`,
  COUNT_PURCHASE_PENDING_TRANSACTIONS_ERROR: `${prefix}_COUNT_PURCHASE_PENDING_TRANSACTIONS_ERROR`,

  TOTAL_FOR_SEND_DIVIDEND_STARTED: `${prefix}_TOTAL_FOR_SEND_DIVIDEND_STARTED`,
  TOTAL_FOR_SEND_DIVIDEND_SUCCESS: `${prefix}_TOTAL_FOR_SEND_DIVIDEND_SUCCESS`,
  TOTAL_FOR_SEND_DIVIDEND_ERROR: `${prefix}_TOTAL_FOR_SEND_DIVIDEND_ERROR`,

  TOTAL_FOR_PENDING_WITHDRAWN_STARTED: `${prefix}_TOTAL_FOR_PENDING_WITHDRAWN_STARTED`,
  TOTAL_FOR_PENDING_WITHDRAWN_SUCCESS: `${prefix}_TOTAL_FOR_PENDING_WITHDRAWN_SUCCESS`,
  TOTAL_FOR_PENDING_WITHDRAWN_ERROR: `${prefix}_TOTAL_FOR_PENDING_WITHDRAWN_ERROR`,

  TOTAL_FOR_PROCEED_WITHDRAWN_STARTED: `${prefix}_TOTAL_FOR_PROCEED_WITHDRAWN_STARTED`,
  TOTAL_FOR_PROCEED_WITHDRAWN_SUCCESS: `${prefix}_TOTAL_FOR_PROCEED_WITHDRAWN_SUCCESS`,
  TOTAL_FOR_PROCEED_WITHDRAWN_ERROR: `${prefix}_TOTAL_FOR_PROCEED_WITHDRAWN_ERROR`,

  GET_VALUATIONS_STARTED: `${prefix}_GET_VALUATIONS_STARTED`,
  GET_VALUATIONS_SUCCESS: `${prefix}_GET_VALUATIONS_SUCCESS`,
  GET_VALUATIONS_ERROR: `${prefix}_GET_VALUATIONS_ERROR`,

  GET_INTERESTS_STARTED: `${prefix}_GET_INTERESTS_STARTED`,
  GET_INTERESTS_SUCCESS: `${prefix}_GET_INTERESTS_SUCCESS`,
  GET_INTERESTS_ERROR: `${prefix}_GET_INTERESTS_ERROR`,

  doCountTotalCustomer: () => async (dispatch) => {
    try {
      dispatch({
        type: dashboardActions.COUNT_TOTAL_CUSTOMER_STARTED,
      });

      const count =
        await DashboardService.countTotalCustomer();

      dispatch({
        type: dashboardActions.COUNT_TOTAL_CUSTOMER_SUCCESS,
        payload: count,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dashboardActions.COUNT_TOTAL_CUSTOMER_ERROR,
      });
    }
  },

  doCountVerifiedCustomer: () => async (dispatch) => {
    try {
      dispatch({
        type: dashboardActions.COUNT_VERIFIED_CUSTOMER_STARTED,
      });

      const count =
        await DashboardService.countVerifiedCustomer();

      dispatch({
        type: dashboardActions.COUNT_VERIFIED_CUSTOMER_SUCCESS,
        payload: count,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dashboardActions.COUNT_VERIFIED_CUSTOMER_ERROR,
      });
    }
  },

  doCountRequestedCustomer: () => async (dispatch) => {
    try {
      dispatch({
        type: dashboardActions.COUNT_REQUESTED_CUSTOMER_STARTED,
      });

      const count =
        await DashboardService.countRequestedCustomer();

      dispatch({
        type: dashboardActions.COUNT_REQUESTED_CUSTOMER_SUCCESS,
        payload: count,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dashboardActions.COUNT_REQUESTED_CUSTOMER_ERROR,
      });
    }
  },

  doCountCountry: () => async (dispatch) => {
    try {
      dispatch({
        type: dashboardActions.COUNT_COUNTRY_STARTED,
      });

      const count = await DashboardService.countCountry();

      dispatch({
        type: dashboardActions.COUNT_COUNTRY_SUCCESS,
        payload: count,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dashboardActions.COUNT_COUNTRY_ERROR,
      });
    }
  },

  doCountPurchasePendingTransactions: () => async (dispatch) => {
    try {
      dispatch({
        type: dashboardActions.COUNT_PURCHASE_PENDING_TRANSACTIONS_STARTED,
      });

      const count =
        await DashboardService.countPurchasePendingTransactions();

      dispatch({
        type: dashboardActions.COUNT_PURCHASE_PENDING_TRANSACTIONS_SUCCESS,
        payload: count,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dashboardActions.COUNT_PURCHASE_PENDING_TRANSACTIONS_ERROR,
      });
    }
  },

  doTotalForSendDividend: () => async (dispatch) => {
    try {
      dispatch({
        type: dashboardActions.TOTAL_FOR_SEND_DIVIDEND_STARTED,
      });

      const total = await DashboardService.totalForSendDividend();

      dispatch({
        type: dashboardActions.TOTAL_FOR_SEND_DIVIDEND_SUCCESS,
        payload: total,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dashboardActions.TOTAL_FOR_SEND_DIVIDEND_ERROR,
      });
    }
  },

  doTotalForPendingWithdrawn: () => async (dispatch) => {
    try {
      dispatch({
        type: dashboardActions.TOTAL_FOR_PENDING_WITHDRAWN_STARTED,
      });

      const total = await DashboardService.totalForPendingWithdrawn();

      dispatch({
        type: dashboardActions.TOTAL_FOR_PENDING_WITHDRAWN_SUCCESS,
        payload: total,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dashboardActions.TOTAL_FOR_PENDING_WITHDRAWN_ERROR,
      });
    }
  },

  doTotalForProceedWithdrawn: () => async (dispatch) => {
    try {
      dispatch({
        type: dashboardActions.TOTAL_FOR_PROCEED_WITHDRAWN_STARTED,
      });

      const total = await DashboardService.totalForProceedWithdrawn();

      dispatch({
        type: dashboardActions.TOTAL_FOR_PROCEED_WITHDRAWN_SUCCESS,
        payload: total,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dashboardActions.TOTAL_FOR_PROCEED_WITHDRAWN_ERROR,
      });
    }
  },

  doGetValuations: (userId) => async (dispatch) => {
    try {
      dispatch({
        type: dashboardActions.GET_VALUATIONS_STARTED,
      });

      const valuations = await DashboardService.getValuations(userId);

      dispatch({
        type: dashboardActions.GET_VALUATIONS_SUCCESS,
        payload: valuations
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dashboardActions.GET_VALUATIONS_ERROR,
      });
    }
  },

  doGetInterests: (userId) => async (dispatch) => {
    try {
      dispatch({
        type: dashboardActions.GET_INTERESTS_STARTED,
      });

      const interests = await DashboardService.getInterests(userId);

      dispatch({
        type: dashboardActions.GET_INTERESTS_SUCCESS,
        payload: interests
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dashboardActions.GET_INTERESTS_ERROR,
      });
    }
  }
};

export default dashboardActions;
