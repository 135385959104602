import actions from 'src/modules/marketplace/view/marketplaceViewActions';

const initialData = {
  loading: false,
  openOrdersList: [],
  filledOrdersList: [],
  acceptedOrdersList: [],
  cancelledOrdersList: [],
  updateLoading: false,
  cancelLoading: false,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      openOrdersList: [],
      filledOrdersList: [],
      acceptedOrdersList: [],
      cancelledOrdersList: [],
      loading: true,
    };
  }

  if (type === actions.FIND_SUCCESS) {
    return {
      ...state,
      openOrdersList: payload.openOrdersList,
      filledOrdersList: payload.filledOrdersList,
      acceptedOrdersList: payload.acceptedOrdersList,
      cancelledOrdersList: payload.cancelledOrdersList,
      loading: false,
    };
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      openOrdersList: [],
      filledOrdersList: [],
      acceptedOrdersList: [],
      cancelledOrdersList: [],
      loading: false,
    };
  }

  if (type === actions.UPDATE_STARTED) {
    return {
      ...state,
      updateLoading: true,
    };
  }

  if (type === actions.UPDATE_SUCCESS) {
    return {
      ...state,
      updateLoading: false,
    };
  }

  if (type === actions.UPDATE_ERROR) {
    return {
      ...state,
      updateLoading: false,
    };
  }

  if (type === actions.CANCEL_STARTED) {
    return {
      ...state,
      cancelLoading: true,
    };
  }

  if (type === actions.CANCEL_SUCCESS) {
    return {
      ...state,
      cancelLoading: false,
    };
  }

  if (type === actions.CANCEL_ERROR) {
    return {
      ...state,
      cancelLoading: false,
    };
  }

  return state;
};
