const prefix = 'WEB3';

const web3Actions = {
  APPROVE_START: `${prefix}_APPROVE_START`,
  APPROVE_END: `${prefix}_APPROVE_END`,
  BUY_TOKEN_START: `${prefix}_BUY_TOKEN_START`,
  BUY_TOKEN_END: `${prefix}_BUY_TOKEN_END`,
  SEND_DIVIDEND_START: `${prefix}_SEND_DIVIDEND_START`,
  SEND_DIVIDEND_END: `${prefix}_SEND_DIVIDEND_END`,
  SEND_DIVIDENDS_START: `${prefix}_SEND_DIVIDENDS_START`,
  SEND_DIVIDENDS_END: `${prefix}_SEND_DIVIDENDS_END`,

  doApproveStart: () => {
    return {
      type: web3Actions.APPROVE_START,
    };
  },

  doApproveEnd: () => {
    return {
      type: web3Actions.APPROVE_END,
    };
  },

  doBuyTokenStart: () => {
    return {
      type: web3Actions.BUY_TOKEN_START,
    };
  },

  doBuyTokenEnd: () => {
    return {
      type: web3Actions.BUY_TOKEN_END,
    };
  },

  doSendDividendStart: () => {
    return {
      type: web3Actions.SEND_DIVIDEND_START,
    };
  },

  doSendDividendEnd: () => {
    return {
      type: web3Actions.SEND_DIVIDEND_END,
    };
  },

  doSendDividendsStart: () => {
    return {
      type: web3Actions.SEND_DIVIDENDS_START,
    };
  },

  doSendDividendsEnd: () => {
    return {
      type: web3Actions.SEND_DIVIDENDS_END,
    };
  },
};

export default web3Actions;
