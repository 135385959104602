
import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

function SEO(props) {
  const { title, description, name, type, locale } = props;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta property='og:type' content={type} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:locale' content={locale} />
      <meta property='og:site_name' content={name} />
      <meta property="og:url" content="https://www.bloktok.io/" />
      <meta property="og:image" content="https://www.bloktok.io/assets/img/bloktok_logo_red_all.png" />
      <meta name='twitter:creator' content={name} />
      <meta name='twitter:card' content={type} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta property="twitter:image" content="https://www.bloktok.io/assets/img/bloktok_logo_red_all.png" />
    </Helmet>
  );
}

SEO.defaultProps = {
  title: '',
  description: '',
  name: 'Bloktok.io',
  type: 'website',
  locale: 'es_ES',
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string
};

export default SEO;
