import React from 'react';
import ReactDOM from 'react-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
} from '@mui/material';
import { selectMuiSettings } from 'src/modules/mui/muiSelectors';
import MDButton from 'src/mui/components/MDButton';
import MDBox from 'src/mui/components/MDBox';
import MDTypography from 'src/mui/components/MDTypography';
import { i18n } from 'src/i18n';
import { makeStyles } from '@mui/styles';
import colors from 'src/mui/assets/theme/base/colors';

const useStyles: any = makeStyles({
  button: {
    borderRadius: 5,
    padding: 20,
    '&:hover': {
      backgroundColor: 'white',
      cursor: 'pointer',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    maxWidth: 100,
  },
  title: {
    fontSize: 30,
    fontWeight: 600,
    color: colors.blue.main,
  },
  description: {
    fontSize: 16,
    color: colors.blue.main,
  },
});

function Web3Modal(props) {
  const classes = useStyles();

  return ReactDOM.createPortal(
    <Dialog
      open={true}
      onClose={props.onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogContent>
        <Grid container spacing={1.6}>
          <Grid item xs={6}>
            <MDBox
              onClick={props.onClick}
              className={classes.button}
            >
              <MDBox className={classes.container}>
                <img
                  src="/images/metamask_wallet.png"
                  className={classes.icon}
                />
              </MDBox>
              <MDBox className={classes.container}>
                <MDTypography
                  color="blue"
                  className={classes.title}
                >
                  {i18n('web3.metamask.title')}
                </MDTypography>
              </MDBox>
              <MDBox className={classes.container}>
                <MDTypography
                  className={classes.description}
                >
                  {i18n('web3.metamask.description')}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox onClick={props.onClick} className={classes.button}>
              <MDBox className={classes.container}>
                <img
                  src="/images/coinbase-wallet-logo.png"
                  className={classes.icon}
                />
              </MDBox>
              <MDBox className={classes.container}>
                <MDTypography className={classes.title}>
                  {i18n('web3.coinbase.title')}
                </MDTypography>
              </MDBox>
              <MDBox className={classes.container}>
                <MDTypography
                  className={classes.description}
                >
                  {i18n('web3.coinbase.description')}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox onClick={props.onClick} className={classes.button}>
              <MDBox className={classes.container}>
                <img
                  src="/images/trust_wallet.png"
                  className={classes.icon}
                />
              </MDBox>
              <MDBox className={classes.container}>
                <MDTypography className={classes.title}>
                  {i18n('web3.trustwallet.title')}
                </MDTypography>
              </MDBox>
              <MDBox className={classes.container}>
                <MDTypography
                  className={classes.description}
                >
                  {i18n('web3.trustwallet.description')}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>,
    (document as any).getElementById('modal-root'),
  );
}

export default Web3Modal;
