import { useState, useEffect } from 'react';

// react-router components
import { useLocation, NavLink } from 'react-router-dom';

// @material-ui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React TS components
import MDBox from 'src/mui/components/MDBox';

// Material Dashboard 2 PRO React TS examples components
import Breadcrumbs from 'src/mui/shared/Breadcrumbs';

// Custom styles for Header
import {
  navbar,
  navbarContainer,
  navbarRow,
} from 'src/mui/shared/Navbars/DashboardNavbar/styles';

// for MUI 2 Dashboard
import muiActions from 'src/modules/mui/muiActions';
import { selectMuiSettings } from 'src/modules/mui/muiSelectors';

import { useDispatch, useSelector } from 'react-redux';
import I18nSelect from 'src/view/layout/I18nSelect';
import { i18n } from 'src/i18n';

import WalletConnectionButton from 'src/view/shared/WalletConnectionButton';
import authSelectors from 'src/modules/auth/authSelectors';
import BlinkingStar from 'src/view/shared/BlinkingStar';
import { useWeb3Context } from 'src/web3/hooks/web3Context';
import getClaimableAmount from 'src/web3/helpers/getClaimableAmount';
import paymentSelectors from 'src/modules/payment/paymentSelectors';
import paymentActions from 'src/modules/payment/paymentActions';
import exchangeRateSelectors from 'src/modules/exchangeRate/exchangeRateSelectors';
import MDTypography from 'src/mui/components/MDTypography';
import * as yup from 'yup';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import exchangeRateEnumerators from 'src/modules/exchangeRate/exchangeRateEnumerators';
import exchangeRateActions from 'src/modules/exchangeRate/exchangeRateActions';
import ufActions from 'src/modules/uf/ufActions';
import FormWrapper from 'src/view/shared/styles/FormWrapper';
import formActions from 'src/modules/form/formActions';
import PropertyService from 'src/modules/property/propertyService';
import PermissionChecker from 'src/modules/auth/permissionChecker';

// Declaring prop types for Header
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
  isCustomer?: boolean;
}

const schema = yup.object().shape({
  currency: yupFormSchemas.enumerator(
    i18n('dashboard.fields.currencySelector.label'),
  )
});

function Header({
  absolute,
  light,
  isMini,
  isCustomer,
}: Props): JSX.Element {
  const [navbarType, setNavbarType] = useState<
    'fixed' | 'absolute' | 'relative' | 'static' | 'sticky'
  >();
  const dispatch = useDispatch();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    darkMode,
  } = selectMuiSettings();
  const [transparentNav, setTransparentNav] = useState(
    transparentNavbar,
  );
  const { connected, web3, provider } =
    useWeb3Context();
  const [isClaimable, setClaimable] = useState(false);

  const route = useLocation().pathname.split('/').slice(1);

  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const currentUserPaymentCategory = useSelector(authSelectors.selectCurrentUserPaymentCategory);
  const status = useSelector(authSelectors.selectStatus);
  const fiat = useSelector(paymentSelectors.selectRecordFIAT);
  const currentCurrency = useSelector(exchangeRateSelectors.selectCurrency);
  const permissionChecker = new PermissionChecker(currentUser);

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: { currency: currentCurrency || 'USD' }
  });

  useEffect(() => {
    // Setting the navbar type
    setNavbarType(fixedNavbar ? 'sticky' : 'static');

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNav(
        (fixedNavbar && window.scrollY === 0) ||
        !fixedNavbar,
      );
    }

    /**
     * The event listener that's calling the handleTransparentNavbar function when
     * scrolling the window.
     */
    window.addEventListener(
      'scroll',
      handleTransparentNavbar,
    );

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () =>
      window.removeEventListener(
        'scroll',
        handleTransparentNavbar,
      );
  }, [dispatch, fixedNavbar]);



  const doSetClaimable = async () => {
    const property = await PropertyService.list({}, null, 100, 0);
    let totalAmount = 0;
    if (property.rows.length) {
      await Promise.all(
        property.rows.map(async (property) => {
          if (property.governanceAddress) {
            const amount = await getClaimableAmount(property.governanceAddress, provider);
            if (amount) {
              totalAmount += amount;
              setClaimable(true);
              return;
            }
          }
        })
      );
    }
    setClaimable(Boolean(totalAmount));
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (connected) doSetClaimable();
    }, 1000);

    return () => {
      clearInterval(interval);
      interval = null;
    }
  }, [web3, connected]);

  useEffect(() => {
    if (currentUserPaymentCategory === 'FIAT' && currentUser) {
      dispatch(paymentActions.doFindFIAT(currentUser.id));
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (currentUserPaymentCategory === 'FIAT' && fiat) {
      setClaimable(Boolean(fiat.balance));
    }
  }, [fiat]);

  useEffect(() => {
    if (currentUserPaymentCategory === 'FIAT') {
      form.setValue("currency", "CLP");
      dispatch(formActions.doRefresh());
      dispatch(exchangeRateActions.doFind('CLP'));
      dispatch(ufActions.doFind());
    } else {
      dispatch(exchangeRateActions.doFind('CLP'));
    }
  }, [currentUser]);

  const handleMiniSidenav = () => {
    window.innerWidth >= 1200 &&
      dispatch(
        muiActions.doSave({
          miniSidenav: !miniSidenav,
        }),
      );
    dispatch(muiActions.doMiniSidenav(!miniSidenav));
  };

  const onChange = async (currency) => {
    if (currency === 'USD' || currency === null) {
      await dispatch(exchangeRateActions.doSetCurrency('USD'));
    } else {
      await dispatch(exchangeRateActions.doFind(currency));
    }
  };

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }: {
    palette: any;
    functions: any;
  }) => ({
    color: () => {
      let colorValue =
        light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode
          ? rgba(text.main, 0.6)
          : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, {
          transparentNavbar: transparentNav,
          absolute,
          light,
          darkMode,
        })
      }
    >
      <Toolbar sx={navbarContainer}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
          <MDBox display={{ xs: 'none', md: 'block' }}>
            <IconButton
              onClick={handleMiniSidenav}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium" sx={iconsStyle}>
                {miniSidenav ? 'menu_open' : 'menu'}
              </Icon>
            </IconButton>
          </MDBox>
        </MDBox>
        <MDBox>
          {
            currentUserPaymentCategory !== 'cryptocurrency' && (
              <MDBox minWidth={{ md: 330 }} display={{ xs: 'none', sm: 'block' }}>
                <FormWrapper>
                  <FormProvider {...form}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDTypography variant="subtitle2" display={{ xs: 'none', md: 'block' }} sx={{ fontWeight: 700 }}>
                        {i18n('dashboard.fields.currency')}
                      </MDTypography>
                      <MDBox minWidth={180} mb={1}>
                        <SelectFormItem
                          name="currency"
                          options={exchangeRateEnumerators.currency.map(
                            value => ({
                              value,
                              label: i18n(`dashboard.fields.currencySelector.${value}`)
                            })
                          )}
                          onChange={onChange}
                          variant='standard'
                        />
                      </MDBox>
                    </MDBox>
                  </FormProvider>
                </FormWrapper>
              </MDBox>
            )
          }
          {
            isClaimable && (
              <NavLink to='/customer/wallet'>
                <BlinkingStar />
              </NavLink>
            )
          }
          {
            (currentUserPaymentCategory === 'cryptocurrency' || permissionChecker.isAdmin) && <WalletConnectionButton />
          }
        </MDBox>
        <MDBox display={{ xs: 'block', md: 'none' }}>
          <IconButton
            onClick={handleMiniSidenav}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? 'menu_open' : 'menu'}
            </Icon>
          </IconButton>
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Declaring default props for Header
Header.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  isCustomer: false,
};

export default Header;
