/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
export default class Storage {
  static get values() {
    return {
      userAvatarsProfiles: {
        id: 'userAvatarsProfiles',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
      frontImages: {
        id: 'frontImages',
        folder: 'user/frontImages/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
      backImages: {
        id: 'backImages',
        folder: 'user/backImages/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
      eRut: {
        id: 'eRut',
        folder: 'user/eRut/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
      companyDeed: {
        id: 'companyDeed',
        folder: 'user/companyDeed/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
      propertyPhotographs: {
        id: 'propertyPhotographs',
        folder: 'property/photographs',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      propertyDocumentEn: {
        id: 'propertyDocumentEn',
        folder: 'property/document/en',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      propertyDocumentEs: {
        id: 'propertyDocumentEs',
        folder: 'property/document/es',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      propertyDocumentPt: {
        id: 'propertyDocumentPt',
        folder: 'property/document/pt',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      propertyDocumentJa: {
        id: 'propertyDocumentJa',
        folder: 'property/document/pa',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      propertyDocumentDe: {
        id: 'propertyDocumentDe',
        folder: 'property/document/de',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      propertyDocumentFr: {
        id: 'propertyDocumentFr',
        folder: 'property/document/fr',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      propertyDocumentHa: {
        id: 'propertyDocumentHa',
        folder: 'property/document/ha',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      propertyDocumentHi: {
        id: 'propertyDocumentHi',
        folder: 'property/document/hi',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
    };
  }
}
