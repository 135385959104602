const TOKEN_DECIMALS = 18;
const CHAIN_ID = 56;
const NETWORK_NAME = 'BSC Mainnet';
const RPC_URL = 'https://bsc-dataseed.binance.org/';
const CURRENCY_SYMBOL = 'BNB';
const BLOCK_EXPLORER_URL = 'https://bscscan.com/';

const USDT_ADDRESS =
  '0x55d398326f99059fF775485246999027B3197955';
const USDC_ADDRESS =
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d';
const BUSD_ADDRESS =
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
const BKTUSD_ADDRESS =
  '0x8f12f949A5e82364602D6bA27DAfFD41FBD7d9ae';

const ADMIN_WALLET_ADDRESS =
  '0x2141096416a0F02c9ec8739cdEAF31858F9c75D8';

const CUSTODY_WALLET_ADDRESS =
  '0x633a7c6Ce6195D8198A8bAF8d30b420a42D3e59D';

export {
  TOKEN_DECIMALS,
  CHAIN_ID,
  NETWORK_NAME,
  RPC_URL,
  CURRENCY_SYMBOL,
  BLOCK_EXPLORER_URL,
  USDT_ADDRESS,
  USDC_ADDRESS,
  BUSD_ADDRESS,
  BKTUSD_ADDRESS,
  ADMIN_WALLET_ADDRESS,
  CUSTODY_WALLET_ADDRESS,
};
