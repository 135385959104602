import PaymentService from "src/modules/payment/paymentService";
import paymentSelectors from "src/modules/payment/paymentSelectors";
import Message from "src/view/shared/message";
import { i18n } from "src/i18n";
import exporterFields from "src/modules/payment/paymentExporterFields";
import Errors from "src/modules/shared/error/errors";
import Exporter from "src/modules/shared/exporter/exporter";
import { getHistory } from "src/modules/store";

const prefix = 'PAYMENT';

const paymentActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  FIND_FIAT_STARTED: `${prefix}_FIND_FIAT_STARTED`,
  FIND_FIAT_SUCCESS: `${prefix}_FIND_FIAT_SUCCESS`,
  FIND_FIAT_ERROR: `${prefix}_FIND_FIAT_ERROR`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,
  RESETED: `${prefix}_RESETED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  GET_PURCHASE_HISTORY_STARTED: `${prefix}_GET_PURCHASE_HISTORY_STARTED`,
  GET_PURCHASE_HISTORY_SUCCESS: `${prefix}_GET_PURCHASE_HISTORY_SUCCESS`,
  GET_PURCHASE_HISTORY_ERROR: `${prefix}_GET_PURCHASE_HISTORY_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: paymentActions.FIND_STARTED,
      });

      const record = await PaymentService.find(id);

      dispatch({
        type: paymentActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: paymentActions.FIND_ERROR,
      });
    }
  },

  doFindFIAT: (investor) => async (dispatch) => {
    try {
      dispatch({
        type: paymentActions.FIND_FIAT_STARTED,
      });

      const record = await PaymentService.findFIAT(investor);

      dispatch({
        type: paymentActions.FIND_FIAT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: paymentActions.FIND_FIAT_ERROR,
      });
    }
  },

  doRequestPurchase: (values) => async () => {
    try {
      await PaymentService.requestPurchase(values);

      Message.success(i18n('fiat.message.doRequestPurchase'));

      getHistory().push('/customer/bank-confirm');
    } catch (error) {
      Errors.handle(error);
    }
  },

  doAcceptPurchase: (id, values) => async (dispatch) => {
    try {
      await PaymentService.purchase(id, values);

      await dispatch(paymentActions.doFetchCurrentFilter());

      Message.success(i18n('fiat.message.doAcceptPurchase'));
    } catch (error) {
      Errors.handle(error);
    }
  },

  doDeclinePurchase: (id, values) => async (dispatch) => {
    try {
      await PaymentService.purchase(id, values);

      await dispatch(paymentActions.doFetchCurrentFilter());

      Message.success(i18n('fiat.message.doDeclinePurchase'));
    } catch (error) {
      Errors.handle(error);
    }
  },

  doAcceptReinvestment: (id) => async (dispatch) => {
    try {
      await PaymentService.acceptReinvestment(id);

      await dispatch(paymentActions.doFetchCurrentFilter());

      Message.success(i18n('fiat.message.doAcceptReinvestment'));
    } catch (error) {
      Errors.handle(error);
    }
  },

  doDeclineReinvestment: (id) => async (dispatch) => {
    try {
      await PaymentService.declineReinvestment(id);

      await dispatch(paymentActions.doFetchCurrentFilter());

      Message.success(i18n('fiat.message.doDeclineReinvestment'));
    } catch (error) {
      Errors.handle(error);
    }
  },

  doAcceptWithdraw: (id, values) => async (dispatch) => {
    try {
      await PaymentService.acceptWithdraw(id, values);

      await dispatch(paymentActions.doFetchCurrentFilter());

      Message.success(i18n('fiat.message.doAcceptWithdraw'));
    } catch (error) {
      Errors.handle(error);
    }
  },

  doDeclineWithdraw: (id, values) => async (dispatch) => {
    try {
      await PaymentService.acceptWithdraw(id, values);

      await dispatch(paymentActions.doFetchCurrentFilter());

      Message.success(i18n('fiat.message.doDeclineWithdraw'));
    } catch (error) {
      Errors.handle(error);
    }
  },

  doSendDividends: (values) => async () => {
    try {
      await PaymentService.sendDividends(values);

      Message.success(i18n('fiat.message.doSendDividends'));
    } catch (error) {
      Errors.handle(error);
    }
  },

  doSendDividend: (values) => async () => {
    try {
      await PaymentService.sendDividend(values);

      Message.success(i18n('fiat.message.doSendDividend'));
    } catch (error) {
      Errors.handle(error);
    }
  },

  doWithdraw: () => async () => {
    try {
      await PaymentService.withdraw();

      Message.success(i18n('fiat.message.doWithdraw'));
    } catch (error) {
      Errors.handle(error);
    }
  },

  doClaimDividend: () => async () => {
    try {
      await PaymentService.claimDividend();

      Message.success(i18n('fiat.message.doClaimDividend'));
    } catch (error) {
      Errors.handle(error);
    }
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: paymentActions.RESETED,
    });

    dispatch(paymentActions.doFetch());
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: paymentActions.EXPORT_STARTED,
      });

      const filter = paymentSelectors.selectFilter(getState());
      const response = await PaymentService.list(
        { ...filter, export: 1 },
        paymentSelectors.selectOrderBy(getState()),
        null,
        null,
      );

      new Exporter(
        exporterFields,
        i18n('fiat.exporterFileName'),
      ).transformAndExportAsExcelFile(response.rows);

      dispatch({
        type: paymentActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: paymentActions.EXPORT_ERROR,
      });
    }
  },

  doChangePagination:
    (pagination) => async (dispatch, getState) => {
      dispatch({
        type: paymentActions.PAGINATION_CHANGED,
        payload: pagination,
      });

      dispatch(paymentActions.doFetchCurrentFilter());
    },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: paymentActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(paymentActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter:
    () => async (dispatch, getState) => {
      const filter = paymentSelectors.selectFilter(getState());
      const rawFilter = paymentSelectors.selectRawFilter(
        getState(),
      );
      dispatch(
        paymentActions.doFetch(
          filter,
          rawFilter,
          true,
        ),
      );
    },

  doFetch:
    (filter?, rawFilter?, keepPagination = true) =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: paymentActions.FETCH_STARTED,
            payload: { filter, rawFilter, keepPagination },
          });

          const response = await PaymentService.list(
            filter,
            paymentSelectors.selectOrderBy(getState()),
            paymentSelectors.selectLimit(getState()),
            paymentSelectors.selectOffset(getState()),
          );

          dispatch({
            type: paymentActions.FETCH_SUCCESS,
            payload: {
              rows: response.rows,
              count: response.count,
            },
          });
        } catch (error) {
          Errors.handle(error);

          dispatch({
            type: paymentActions.FETCH_ERROR,
          });
        }
      },

  doGetPurchaseHistory: (propertyId) => async (dispatch) => {
    try {
      dispatch({
        type: paymentActions.GET_PURCHASE_HISTORY_STARTED
      });

      const records = await PaymentService.getPurchaseHistory(propertyId);

      dispatch({
        type: paymentActions.GET_PURCHASE_HISTORY_SUCCESS,
        payload: records
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: paymentActions.GET_PURCHASE_HISTORY_ERROR
      });
    }
  }
};

export default paymentActions;
