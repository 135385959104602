import ExchangeRateService from "src/modules/exchangeRate/exchangeRateService";
import Errors from "src/modules/shared/error/errors";

const prefix = 'EXCHANGE_RATE';

const exchangeRateActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  SET_CURRENCY: `${prefix}_SET_CURRENCY`,

  doFind: (currency) => async (dispatch) => {
    try {
      dispatch({
        type: exchangeRateActions.SET_CURRENCY,
        payload: currency,
      });

      dispatch({
        type: exchangeRateActions.FIND_STARTED,
      });

      const record = await ExchangeRateService.find(currency);

      dispatch({
        type: exchangeRateActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: exchangeRateActions.FIND_ERROR,
      });
    }
  },

  doFetch: () =>
    async (dispatch) => {
      try {
        dispatch({
          type: exchangeRateActions.FETCH_STARTED,
        });

        const response = await ExchangeRateService.list();

        dispatch({
          type: exchangeRateActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: exchangeRateActions.FETCH_ERROR,
        });
      }
    },

  doSetCurrency: (currency) =>
    async (dispatch) => {
      dispatch({
        type: exchangeRateActions.SET_CURRENCY,
        payload: currency
      });
    }
};

export default exchangeRateActions;
