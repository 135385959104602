import actions from 'src/modules/payment/paymentActions';

const INITIAL_PAGE_SIZE = 10;

const initialState = {
  rows: [] as Array<any>,
  record: null,
  recordFIAT: null,
  count: 0,
  initLoading: false,
  findLoading: false,
  exportLoading: false,
  filter: {},
  rawFilter: {},
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  sorter: {},
  purchaseHistory: [] as Array<any>,
  getPurchaseHistoryLoading: false,
};

export default (
  state = initialState,
  { type, payload },
) => {
  if (type === actions.RESETED) {
    return {
      ...state,
    };
  }

  if (type === actions.PAGINATION_CHANGED) {
    return {
      ...state,
      pagination: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.SORTER_CHANGED) {
    return {
      ...state,
      sorter: payload || {},
    };
  }

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      initLoading: true,
      filter: payload ? payload.filter : {},
      rawFilter: payload ? payload.rawFilter : {},
      pagination:
        payload && payload.keepPagination
          ? state.pagination
          : {
            current: 1,
            pageSize: INITIAL_PAGE_SIZE,
          },
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      initLoading: false,
      rows: payload.rows,
      count: payload.count,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      initLoading: false,
      rows: [],
      count: 0,
    };
  }
  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      record: null,
      findLoading: true,
    };
  }

  if (type === actions.FIND_SUCCESS) {
    return {
      ...state,
      record: payload,
      findLoading: false,
    };
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      record: null,
      findLoading: false,
    };
  }

  if (type === actions.FIND_FIAT_STARTED) {
    return {
      ...state,
      recordFIAT: null,
      findLoading: true,
    };
  }

  if (type === actions.FIND_FIAT_SUCCESS) {
    return {
      ...state,
      recordFIAT: payload,
      findLoading: false,
    };
  }

  if (type === actions.FIND_FIAT_ERROR) {
    return {
      ...state,
      recordFIAT: null,
      findLoading: false,
    };
  }

  if (type === actions.EXPORT_STARTED) {
    return {
      ...state,
      exportLoading: true,
    };
  }

  if (type === actions.EXPORT_SUCCESS) {
    return {
      ...state,
      exportLoading: false,
    };
  }

  if (type === actions.EXPORT_ERROR) {
    return {
      ...state,
      exportLoading: false,
    };
  }

  if (type === actions.GET_PURCHASE_HISTORY_STARTED) {
    return {
      ...state,
      getPurchaseHistoryLoading: true,
      purchaseHistory: []
    };
  }

  if (type === actions.GET_PURCHASE_HISTORY_SUCCESS) {
    return {
      ...state,
      getPurchaseHistoryLoading: false,
      purchaseHistory: payload
    };
  }

  if (type === actions.GET_PURCHASE_HISTORY_ERROR) {
    return {
      ...state,
      getPurchaseHistoryLoading: false,
      purchaseHistory: []
    };
  }

  return state;
};
