import { useEffect, useState } from 'react';
import { CHAIN_ID } from 'src/web3/constants/index';
import { CircularProgress } from '@mui/material';
import MDButton from 'src/mui/components/MDButton';
import { useWeb3Context } from 'src/web3/hooks/web3Context';
import authSelectors from 'src/modules/auth/authSelectors';
import { i18n } from 'src/i18n';
import { useSelector } from 'react-redux';
import Message from 'src/view/shared/message';
import Web3Modal from 'src/view/shared/Web3Modal';
import MDBox from 'src/mui/components/MDBox';

function WalletConnectionButton(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const {
    connect,
    address,
    disconnect,
    connected,
    web3,
    providerChainID,
    checkWrongNetwork,
  } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);

  const checkNetwork = () => {
    checkWrongNetwork();
  };

  useEffect(() => {
    setConnected(connected);
    if (
      connected &&
      currentUser &&
      currentUser.role === 'customer'
    ) {
      if (currentUser.status === 'active' && currentUser.walletAddress?.toLowerCase() !== address.toLowerCase()) {
        Message.error(i18n('web3.message.wrongAddress'));
        disconnect();
      } else if (currentUser.status !== 'active') {
        Message.error(i18n('web3.message.unverified'));
        disconnect();
      }
    }
  }, [web3, address, connected, currentUser]);

  const doOpenModal = () => {
    setModalVisible(true);
  };

  const doClose = () => {
    setModalVisible(false);
  };

  const doClick = () => {
    if (window.ethereum) {
      connect();
    } else {
      Message.error(i18n('web3.message.notInstalled'));
    }
    setModalVisible(false);
  };

  return (
    <>
      <MDButton
        color={
          isConnected && providerChainID === CHAIN_ID
            ? 'success'
            : 'red'
        }
        onClick={
          isConnected
            ? providerChainID === CHAIN_ID
              ? disconnect
              : checkNetwork
            : doOpenModal
        }
        sx={{
          fontWeight: 'medium',
          px: 1.5,
          py: 0.8,
        }}
      >
        {isConnected ? (
          providerChainID === CHAIN_ID ? (
            <MDBox
              color="white"
              sx={{
                maxWidth: 70,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {address}
            </MDBox>
          ) : (
            i18n('web3.fields.wrongNetwork')
          )
        ) : (
          i18n('web3.fields.connect')
        )}
      </MDButton>

      {modalVisible && (
        <Web3Modal onClick={doClick} onClose={doClose} />
      )}
    </>
  );
}

export default WalletConnectionButton;
