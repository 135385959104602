import React from 'react';
import { makeStyles } from '@mui/styles';
import StarIcon from '@mui/icons-material/Star';
import { Icon } from '@iconify/react';

const useStyles = makeStyles({
  blink: {
    animation: '$blinkEffect 1s infinite',
    paddingRight: 4,
  },
  '@keyframes blinkEffect': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.4
    },
    '100%': {
      opacity: 1,
    },
  },
});

const BlinkingStar = () => {
  const classes = useStyles();

  return (
    <Icon icon="ph:star-fill" color="orange" width="24" height="24" className={classes.blink} />
  );
};

export default BlinkingStar;