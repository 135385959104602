import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'authorizationCode',
    label: i18n('fiat.fields.authorizationCode'),
  },
  {
    name: 'investor',
    label: i18n('fiat.fields.investor'),
    render: exporterRenders.relations(['id', 'fullName']),
  },
  {
    name: 'createdAt',
    label: i18n('fiat.fields.date'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'property',
    label: i18n('fiat.fields.token'),
    render: exporterRenders.relations(['tokenName', 'tokenPrice']),
  },
  {
    name: 'tokenAmount',
    label: i18n('fiat.fields.tokenAmount'),
  },
  {
    name: 'amount',
    label: i18n('fiat.fields.amount'),
  },
  {
    name: 'paymentMethod',
    label: i18n('fiat.fields.paymentMethod'),
  },
  {
    name: 'action',
    label: i18n('fiat.fields.action'),
  },
  {
    name: 'status',
    label: i18n('fiat.fields.status'),
  },
];
