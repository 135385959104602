import list from 'src/modules/marketplace/list/marketplaceListReducers';
import form from 'src/modules/marketplace/form/marketplaceFormReducers';
import view from 'src/modules/marketplace/view/marketplaceViewReducers';
import orderBook from 'src/modules/marketplace/orderBook/marketplaceOrderBookReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  orderBook,
});
