import authAxios from 'src/modules/shared/axios/authAxios';

export default class DashboardService {
  static async countTotalCustomer() {
    const response = await authAxios.get(
      `/statistics/countTotalCustomer`,
    );

    return response.data;
  }

  static async countVerifiedCustomer() {
    const response = await authAxios.get(
      `/statistics/countVerifiedCustomer`,
    );

    return response.data;
  }

  static async countRequestedCustomer() {
    const response = await authAxios.get(
      `/statistics/countRequestedCustomer`,
    );

    return response.data;
  }

  static async countCountry() {
    const response = await authAxios.get(
      `/statistics/countCountry`,
    );

    return response.data;
  }

  static async countPurchasePendingTransactions() {
    const response = await authAxios.get('/statistics/count-pending-transactions');

    return response.data;
  }

  static async totalForSendDividend() {
    const response = await authAxios.get('/statistics/total-for-sendDividend');

    return response.data;
  }

  static async totalForPendingWithdrawn() {
    const response = await authAxios.get('/statistics/total-for-pendingWithdrawn');

    return response.data;
  }

  static async totalForProceedWithdrawn() {
    const response = await authAxios.get('/statistics/total-for-proceedWithdrawn');

    return response.data;
  }

  static async getValuations(userId) {
    const response = await authAxios.get(`/payment/valuations/${userId}`);

    return response.data;
  }

  static async getInterests(userId) {
    const response = await authAxios.get(`/payment/interests/${userId}`);

    return response.data;
  }
}
