import Roles from 'src/security/roles';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;

class Permissions {
  static get values() {
    return {
      adminEdit: {
        id: 'adminEdit',
        allowedRoles: [roles.admin],
      },
      adminDestroy: {
        id: 'adminDestroy',
        allowedRoles: [roles.admin],
      },
      adminImport: {
        id: 'adminImport',
        allowedRoles: [roles.admin],
      },
      adminCreate: {
        id: 'adminCreate',
        allowedRoles: [roles.admin],
      },
      adminRead: {
        id: 'adminRead',
        allowedRoles: [roles.admin],
      },
      adminAutocomplete: {
        id: 'adminAutocomplete',
        allowedRoles: [roles.admin],
      },

      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.admin],
      },
      muiEdit: {
        id: 'muiEdit',
        allowedRoles: [
          roles.admin,
          roles.manager,
          roles.customer,
        ],
      },

      customerEdit: {
        id: 'customerEdit',
        allowedRoles: [roles.admin, roles.manager],
        allowedStorage: [storage.frontImages, storage.backImages],
      },
      customerDestroy: {
        id: 'customerDestroy',
        allowedRoles: [roles.admin, roles.manager],
        allowedStorage: [storage.frontImages, storage.backImages],
      },
      customerCreate: {
        id: 'customerCreate',
        allowedRoles: [roles.admin, roles.manager],
        allowedStorage: [storage.frontImages, storage.backImages],
      },
      customerRead: {
        id: 'customerRead',
        allowedRoles: [roles.admin, roles.manager],
      },
      customerAutocomplete: {
        id: 'customerAutocomplete',
        allowedRoles: [roles.admin, roles.manager],
      },

      propertyImport: {
        id: 'propertyImport',
        allowedRoles: [roles.admin, roles.manager],
      },
      propertyCreate: {
        id: 'propertyCreate',
        allowedRoles: [roles.admin, roles.manager],
        allowedStorage: [
          storage.propertyPhotographs,
          storage.propertyDocumentEn,
          storage.propertyDocumentEs,
          storage.propertyDocumentPt,
          storage.propertyDocumentJa,
          storage.propertyDocumentDe,
          storage.propertyDocumentFr,
          storage.propertyDocumentHa,
          storage.propertyDocumentHi
        ],
      },
      propertyEdit: {
        id: 'propertyEdit',
        allowedRoles: [
          roles.admin,
          roles.manager,
          roles.customer,
        ],
        allowedStorage: [
          storage.propertyPhotographs,
          storage.propertyDocumentEn,
          storage.propertyDocumentEs,
          storage.propertyDocumentPt,
          storage.propertyDocumentJa,
          storage.propertyDocumentDe,
          storage.propertyDocumentFr,
          storage.propertyDocumentHa,
          storage.propertyDocumentHi
        ],
      },
      propertyDestroy: {
        id: 'propertyDestroy',
        allowedRoles: [roles.admin, roles.manager],
        allowedStorage: [
          storage.propertyPhotographs,
          storage.propertyDocumentEn,
          storage.propertyDocumentEs,
          storage.propertyDocumentPt,
          storage.propertyDocumentJa,
          storage.propertyDocumentDe,
          storage.propertyDocumentFr,
          storage.propertyDocumentHa,
          storage.propertyDocumentHi
        ],
      },
      propertyRead: {
        id: 'propertyRead',
        allowedRoles: [
          roles.admin,
          roles.manager,
          roles.customer,
        ],
      },
      propertyAutocomplete: {
        id: 'propertyAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.manager,
          roles.customer,
        ],
      },

      paymentCreate: {
        id: 'paymentCreate',
        allowedRoles: [roles.admin, roles.manager, roles.customer],
      },
      paymentEdit: {
        id: 'paymentEdit',
        allowedRoles: [
          roles.admin,
          roles.manager,
          roles.customer
        ],
      },
      paymentDestroy: {
        id: 'paymentDestroy',
        allowedRoles: [roles.admin, roles.manager],
      },
      paymentRead: {
        id: 'paymentRead',
        allowedRoles: [
          roles.admin,
          roles.manager,
          roles.customer,
        ],
      },

      transactionCreate: {
        id: 'transactionCreate',
        allowedRoles: [roles.admin, roles.manager, roles.customer],
      },
      transactionEdit: {
        id: 'transactionEdit',
        allowedRoles: [roles.admin, roles.manager, roles.customer],
      },
      transactionRead: {
        id: 'transactionRead',
        allowedRoles: [roles.admin, roles.manager, roles.customer],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
